import LogoutIcon from "@mui/icons-material/Logout";
import {
  Avatar,
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import { icon_fa_user } from "assets/svgs/fontawesomeSvgs";
import CmsImage from "components/cms/cmsImage/cmsImage";
import useUserAuthentication from "hooks/useUserAuthentication";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { globalSettings } from "services/globalSettings/globalSettingsService";
import { userLogoutAction } from "store/actionCreators/userActionCreator";
import { cmsHasFeature } from "utils/clientUtil";
import { CMS_FEATURE_USERAPI } from "utils/constants";

const NavUserIcon = ({ icon, width, height }) => {
  const { t: tPublic } = useTranslation("public");
  const router = useRouter();
  const dispatch = useDispatch();
  const isUserAuthenticated = useUserAuthentication();
  const isCmsUserAuthenticated = useSelector(
    (state) => state.cms.isCmsUserAuthenticated
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    // Prevent cmsUser from accessing userControls.
    if (!isCmsUserAuthenticated) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return cmsHasFeature(CMS_FEATURE_USERAPI) && isUserAuthenticated ? (
    <>
      <Box
        className="user-icon-wrapper"
        sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
      >
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          className="user-icon-button"
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Avatar sx={{ width: width, height: height }}>
            {icon ? (
              <CmsImage
                // globalSettings.navigation.userIcon
                img={icon.url}
                alt={tPublic("logout")}
                title={tPublic("logout")}
                width={width}
                height={height}
                maxWidth={width}
                maxHeight={height}
                objectFit="cover"
              />
            ) : (
              icon_fa_user("svg-fill-white", width, height, {
                fill: "grey",
              })
            )}
          </Avatar>
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        className="user-icon-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: width,
              height: height,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              // Prevent "visible Arrow" to be completely misplaced when settings are not set.
              right: height / 2 ? height / 2 : 4,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => {
            dispatch(userLogoutAction(false, router));
            handleClose();
          }}
        >
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          {tPublic("logout")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch(userLogoutAction(true, router));
            handleClose();
          }}
        >
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          {tPublic("logoutAllDevices")}
        </MenuItem>
      </Menu>

      <style jsx global>
        {`
          .user-icon-wrapper {
            padding-left: ${globalSettings.navigation.userIconPaddingLeft}px;
            padding-right: ${globalSettings.navigation.userIconPaddingRight}px;
            .user-icon-button {
              margin: 0;
              padding: 5px;
            }

            .MuiAvatar-root {
              background-color: transparent;
            }
          }
        `}
      </style>
    </>
  ) : null;
};

export default NavUserIcon;
